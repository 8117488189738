import { Extension, Plugin } from 'tiptap'
import { DecorationSet, Decoration } from 'prosemirror-view'

export default class Placeholder extends Extension {
  get name() {
    return 'placeholder'
  }

  get defaultOptions() {
    return {
      emptyEditorClass: 'is-editor-empty',
      placeholderText: 'Нажмите и начните вводить текст',
    }
  }

  get update() {
    return view => view.updateState(view.state)
  }

  get plugins() {
    return [
      new Plugin({
        props: {
          decorations: ({ doc, schema }) => {
            const decorations = []

            const isEditorEmpty = doc.textContent.length === 0
            if(!isEditorEmpty) {
              return DecorationSet.create(doc, decorations)
            }

            doc.descendants((node, pos) => {
              if(pos !== 0) {
                return false
              }

              if(node.type !== schema.nodes.paragraph) {
                return false
              }

              const decoration = Decoration.node(pos, pos + node.nodeSize, {
                class: this.options.emptyEditorClass,
                'data-placeholder-text': this.options.placeholderText,
              })

              decorations.push(decoration)
              return false
            })

            return DecorationSet.create(doc, decorations)
          }
        }
      })
    ]
  }
}

<template>
  <div class="attributer-overlay" @click="hide" @keyup.ctrl.8="remove" @keyup.esc.exact="hide">
    <div class="attributer" @click="depropagator">
      <template v-for="field in attrs.schema">
        <input
          v-if="field.type === 'text'"
          :key="field.key"
          :placeholder="field.placeholder"
          v-model="attrs.data[field.key]"
          @keyup.enter="save"
        />
      </template>
      <template v-for="field in attrs.schema">
        <div v-if="field.type === 'segments'" class="segments" :key="field.key">
          <div
            v-for="segment in field.values"
            class="segment"
            :key="segment.value"
            :class="{ active: attrs.data[field.key] === segment.value }"
            @click="() => { attrs.data[field.key] = segment.value }"
          >
            {{ segment.displayName }}
          </div>
        </div>
      </template>
      <div class="button save" @click="save">
        Сохранить
      </div>
      <div class="button remove" @click="remove">
        Удалить {{ attrs.removeSubstring }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Attributer',
  props: [
    'attrs'
  ],

  mounted() {
    console.log(this.attrs)
  },

  methods: {
    depropagator(event) {
      event.stopPropagation()
    },

    hide() {
      this.attrs.isShown = false
    },

    save(event) {
      event.preventDefault()

      let shouldHide = true
      if(this.attrs.onSave) {
        shouldHide = this.attrs.onSave()
      }
      if(shouldHide) {
        this.hide()
      }
    },

    remove(event) {
      event.preventDefault()

      let shouldHide = true
      if(this.attrs.onRemove) {
        shouldHide = this.attrs.onRemove()
      }
      if(shouldHide) {
        this.hide()
      }
    },

    tmp(event) {
      console.log(event)
    }
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../../shared.less';

  .attributer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: fade(black, 50%);
    z-index: @z-index-global-overlay;
    cursor: auto;
    user-select: none;

    display: flex;
  }

  .attributer {
    padding: 5px;
    margin: auto;
    background-color: @color-block-background;
    width: 400px;
    box-sizing: border-box;
  }

  input {
    .input();
    width: 100%;
  }

  .button {
    .big-button();
    text-align: center;
    padding: 0.4em 0;

    &.save {
      background-color: @color-accent;
    }

    &.remove {
      background-color: @color-error;
      margin-top: @xxl-margin;
    }
  }

  .segments {
    height: 35px;
    width: 100%;
    border: 1px solid @color-accent;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 5px;
  }

  .segment {
    flex-basis: 0;
    flex-grow: 1;
    border-right: 1px solid @color-accent;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    &:last-child {
      border-right: unset;
    }

    &.active {
      background-color: @color-accent;
      color: @color-block-background;
      cursor: default;
    }
  }
</style>

<template>
  <main>
    <button @click="getJSON" v-if="$root.globalParams.isDebug">Получить JSON</button>
    <div class="main-wrap" v-if="content">
      <section>
        <div class="meta-fields">
          <template v-if="isNewContent">
            <h1 v-if="type === 'article'">Написать статью</h1>
            <h1 v-if="type === 'discussion'">Начать дискуссию</h1>
            <h1 v-if="type === 'event'">Рассказать о событии</h1>
            <h1 v-if="type === 'question'">Задать вопрос</h1>
          </template>
          <template v-if="!isNewContent">
            <h1 v-if="type === 'article'">Редактирование статьи</h1>
          </template>
          <template>
            <div class="promo-text" v-if="type === 'article'">
              <p>
                Статьи в Helperia бывают авторскими и публичными.
                Публичные статьи доступны для редактирования другим пользователям.
              </p>
              <p>
                Не размещайте полностью скопированные материалы, принадлежащие другим авторам (см.
                <router-link :to="{ name: 'about-terms' }">Пользовательское соглашение</router-link>
                и
                <router-link :to="{ name: 'about-privacy' }">Политика конфиденциальности</router-link>).
              </p>
              <p>
                Важно правильно оформлять Ваши статьи.
                Разбивайте текст на разделы с помощью подзаголовков, пользуйтесь инструментом «цитата»,
                выделяйте важное с помощью курсива.
                Загрузите фотографии и/или видео в текст статьи.
                Подберите хорошую обложку для карточки.
              </p>
              <p>
                Данные действия повысят позиции вашей статьи в ТОПе глобальных поисковых систем и Helperia.
              </p>
            </div>
            <div class="promo-text" v-if="type === 'discussion'">
              <p>Давайте обсудим интересующие нас проблемы.</p>
              <p>Поговорим на горячие темы.</p>
              <p>Обменяемся мнениями и получим советы от разных людей.</p>
              <p>Привлекайте к дискуссии экспертов и интересных людей, размещайте ссылки на дискуссию на других сайтах для повышения интереса к дискуссии и привлечения к ней экспертов и интересных людей, чтобы вместе найти решение.</p>
            </div>
            <div class="promo-text" v-if="type === 'event'">
              <p>Вы создаете интересное событие, расскажите о нем.</p>
              <p>Разместите анонс. Привлекайте гостей и партнеров.</p>
              <p>Вы посетили интересное событие, оставьте отзыв.</p>
              <p>Вам не обязательно быть организатором события, чтобы рассказать о нем или оставить отзыв.</p>
            </div>
            <div class="promo-text" v-if="type === 'question'">
              <p>
                Задайте свой вопрос!
                Все вопросы анонимны.
                Если вы хотите оперативно видеть ответы на свой вопрос и иметь удобный доступ к нему из своего личного кабинета – <router-link :to="{ name: 'register' }">зарегистрируйтесь</router-link>.
              </p>
              <p>
                Загрузите тематическое изображение – так с большей вероятностью ваш вопрос заметят эксперты и Вы получите быстрый и правильный ответ.
                Проверьте текст на ошибки.
              </p>
            </div>
          </template>
          <div class="fields">
            <div class="segments" v-if="type === 'article'">
              <div
                class="segment"
                :class="{ active: content.isPubliclyEditable }"
                @click="content.isPubliclyEditable = true"
              >Публичная</div>
              <div
                class="segment"
                :class="{ active: !content.isPubliclyEditable }"
                @click="content.isPubliclyEditable = false"
              >Авторская</div>
            </div>
            <div class="input-field" v-if="type === 'event'">
              <label for="field_format" class="required">Формат</label>
              <textarea
                id="field_format"
                rows="1"
                placeholder="Лекция, семинар, мастер-класс, спектакль, выставка, фестиваль…"
                autocomplete="off"
                v-model="content.format"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'article'">
              <label for="field_title_a" class="required">Заголовок</label>
              <textarea
                id="field_title_a"
                rows="1"
                placeholder="Придумайте оригинальный и краткий заголовок"
                autocomplete="off"
                v-model="title"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'discussion'">
              <label for="field_title_d" class="required">Тема дискуссии</label>
              <textarea
                id="field_title_d"
                rows="1"
                placeholder="Напишите тему для дискуссии"
                autocomplete="off"
                v-model="title"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'event'">
              <label for="field_title_e" class="required">Название</label>
              <textarea
                id="field_title_e"
                rows="1"
                placeholder="Напишите название мероприятия"
                autocomplete="off"
                v-model="title"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'question'">
              <label for="field_title_q" class="required">Текст вопроса</label>
              <textarea
                id="field_title_q"
                rows="3"
                placeholder="Сразу пишите текст вопроса – не приветствуйте пользователей. Коротко отразите суть, сообщите важные детали. Постарайтесь, чтобы вопрос умещался на карточке материала."
                autocomplete="off"
                v-model="title"
              ></textarea>
            </div>
            <div class="input-field">
              <label for="field_slug" class="required">Ссылка</label>
              <textarea
                id="field_slug"
                rows="1"
                placeholder="Ссылка создается автоматически, но вы можете её изменить"
                autocomplete="off"
                v-model="slug"
                :disabled="isNewContent ? undefined : 'true'"
              ></textarea>
            </div>
            <div class="input-field" v-if="type !== 'question'">
              <label for="field_tags" class="required">Теги</label>
              <textarea
                id="field_tags"
                rows="3"
                placeholder="Теги помогают искать материалы по теме. Указывайте теги с большой буквы, разделяя каждый тег запятой или пробелом. Если в теге несколько слов, пишите все слитно, каждое слово с большой буквы."
                autocomplete="off"
                v-model="tags"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'event'">
              <label for="field_organizer" class="required">Организатор</label>
              <textarea
                id="field_organizer"
                rows="1"
                placeholder="Укажите правильно лицо или компанию организатора события"
                autocomplete="off"
                v-model="content.organizer"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'event'">
              <label for="field_phone">Телефон</label>
              <textarea
                id="field_phone"
                rows="1"
                placeholder="+7 (000) 123-45-67"
                autocomplete="off"
                v-model="content.phone"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'event'">
              <label for="field_website">Сайт мероприятия / организатора</label>
              <textarea
                id="field_website"
                rows="1"
                placeholder="https://…"
                autocomplete="off"
                v-model="content.website"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'event'">
              <label for="field_address" class="required">Адрес проведения</label>
              <textarea
                id="field_address"
                rows="2"
                placeholder="Укажите точный адрес события: город, улица, дом, офис/помещение, метро, если есть"
                autocomplete="off"
                v-model="content.address"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'event'">
              <label for="field_date" class="required">Дата(ы) проведения</label>
              <textarea
                id="field_date"
                rows="2"
                placeholder="Примеры: 01.01.2020 — один день; 01.01.2020, 05.01.2020 — 1 и 5 января; 01.01.2020-05.01.2020 — каждый день с 1 по 5 января"
                autocomplete="off"
                v-model="content.date"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'event'">
              <label for="field_time" class="required">Время проведения</label>
              <textarea
                id="field_time"
                rows="2"
                placeholder="Примеры: 14:00 — дата начала мероприятия; 14:00, 15:00, 16:00 — начало в 14, 15 и 16 часов; 14:00-16:00 — начало в 14, заканчивается в 16"
                autocomplete="off"
                v-model="content.time"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'event'">
              <label for="field_price">Стоимость билета</label>
              <textarea
                id="field_price"
                rows="1"
                placeholder="Укажите стоимость; если бесплатно, оставьте поле пустым"
                autocomplete="off"
                v-model="content.price"
              ></textarea>
            </div>
            <div class="input-field" v-if="type === 'article'">
              <label class="required">Основной текст статьи</label>
            </div>
            <div class="input-field" v-if="type === 'discussion'">
              <label class="required">Описание дискуссии</label>
            </div>
            <div class="input-field" v-if="type === 'event'">
              <label class="required">Описание события</label>
            </div>
            <div v-if="$root.globalParams.isDebug" class="debug-paster" contenteditable="true" @paste="debugPasted">
              Debug Paster
            </div>
          </div>
        </div>
        <article v-show="!$root.auth" v-if="type !== 'question'">
          <div class="auth-info">
            <span>
              Для того, чтобы написать материал,<br/>
              нужно <!--suppress HtmlUnknownAnchorTarget -->
              <a href="#login" @click="showLogInOverlay">войти</a> или зарегистрироваться.
            </span>
          </div>
        </article>
        <article v-show="$root.auth" v-if="type !== 'question'">
          <EditorMenuBar :editor="editor" v-slot="{ commands, isActive }">
            <div class="editor-menu-bar">
              <button :class="{ active: isActive.bold() }" @click="commands.bold()" title="Жирный">
                <img src="../assets/editor-icons/bold.svg" alt="Жирный" draggable="false">
              </button>
              <button :class="{ active: isActive.italic() }" @click="commands.italic()" title="Курсив">
                <img src="../assets/editor-icons/italic.svg" alt="Курсив" draggable="false">
              </button>
              <button :class="{ active: isActive.underline() }" @click="commands.underline()" title="Подчёркивание">
                <img src="../assets/editor-icons/underline.svg" alt="Подчёркивание" draggable="false">
              </button>
              <button :class="{ active: isActive.link() }" @click="commands.link()" title="Ссылка">
                <img src="../assets/editor-icons/link.svg" alt="Ссылка" draggable="false">
              </button>
              <button
                :class="{ active: isActive.heading({ level: 1 }) }"
                @click="commands.heading({ level: 1 })"
                title="Заголовок первого уровня"
              >
                <img src="../assets/editor-icons/h1.svg" alt="Заголовок первого уровня" draggable="false" />
              </button>
              <button
                :class="{ active: isActive.heading({ level: 2 }) }"
                @click="commands.heading({ level: 2 })"
                title="Заголовок второго уровня"
              >
                <img src="../assets/editor-icons/h2.svg" alt="Заголовок второго уровня" draggable="false" />
              </button>
              <button
                :class="{ active: isActive.heading({ level: 3 }) }"
                @click="commands.heading({ level: 3 })"
                title="Заголовок третьего уровня"
              >
                <img src="../assets/editor-icons/h3.svg" alt="Заголовок третьего уровня" draggable="false" />
              </button>
              <button
                :class="{ active: isActive.bullet_list() }"
                @click="commands.bullet_list()"
                title="Маркированный список"
              >
                <img src="../assets/editor-icons/bullet-list.svg" alt="Маркированный список" draggable="false" />
              </button>
              <button
                :class="{ active: isActive.ordered_list() }"
                @click="commands.ordered_list()"
                title="Нумерованный список"
              >
                <img src="../assets/editor-icons/ordered-list.svg" alt="Нумерованный список" draggable="false" />
              </button>
              <button :class="{ active: isActive.blockquote() }" @click="commands.blockquote()" title="Цитата">
                <img src="../assets/editor-icons/quote.svg" alt="Цитата" draggable="false" />
              </button>
              <button
                :class="{ active: isActive.code_block() }"
                @click="commands.code_block()"
                title="Код (моноширинный текст)"
              >
                <img src="../assets/editor-icons/code.svg" alt="Код (моноширинный текст)" draggable="false" />
              </button>
            </div>
          </EditorMenuBar>
          <EditorContent :editor="editor" class="body editor-content" />
          <EditorFloatingMenu :editor="editor" v-slot="{ commands, menu }">
            <div class="editor-floating-menu" :class="{ active: menu.isActive }" :style="`--menu-top: ${menu.top}px;`">
              <button @click="addImageClicked" title="Изображение">
                <img src="../assets/editor-icons/image.svg" alt="Изображение" draggable="false" />
              </button>
              <button @click="commands.video()" title="Видео">
                <img src="../assets/editor-icons/video.svg" alt="Видео" draggable="false" />
              </button>
            </div>
          </EditorFloatingMenu>
        </article>
      </section>
      <aside>
        <div class="sticky-wrapper">
          <div>
            <div class="button publish" @click="publish" :class="{ disabled: !$root.auth }">Опубликовать</div>
          </div>
          <input
            type="file"
            class="file-upload-input"
            ref="fileInput"
            accept="image/jpeg, image/png"
            @change="uploadCover"
          />
          <div class="content-card-wrapper" @click="selectCoverFile" :class="{ small: type === 'question' }">
            <ContentCard :card-data="content" :is-in-editor="true" :card-type="type" />
          </div>
        </div>
      </aside>
    </div>
    <Attributer v-if="$root['attributerAttrs'].isShown" :attrs="$root['attributerAttrs']" />
  </main>
</template>

<script>
import { Editor, EditorContent, EditorFloatingMenu, EditorMenuBar } from 'tiptap'
import ContentCard from '@/components/Shared/Cards/ContentCard'
import slugify from 'slugify'
import axios from 'axios'

import Heading from '@/tiptap-helperia-extensions/Heading'
import Bold from '@/tiptap-helperia-extensions/Bold'
import Italic from '@/tiptap-helperia-extensions/Italic'
import Underline from '@/tiptap-helperia-extensions/Underline'
import Image from '@/tiptap-helperia-extensions/Image'
import Video from '@/tiptap-helperia-extensions/Video'
import Link from '@/tiptap-helperia-extensions/Link'
import ListItem from '@/tiptap-helperia-extensions/ListItem'
import BulletList from '@/tiptap-helperia-extensions/BulletList'
import OrderedList from '@/tiptap-helperia-extensions/OrderedList'
import Placeholder from '@/tiptap-helperia-extensions/Placeholder'
import Blockquote from '@/tiptap-helperia-extensions/Blockquote'
import History from '@/tiptap-helperia-extensions/History'
import CodeBlock from '@/tiptap-helperia-extensions/CodeBlock'
import TrailingParagraph from '@/tiptap-helperia-extensions/TrailingParagraph'

import Attributer from '@/components/ContentEditor/Attributer'

require('formdata-polyfill')

export default {
  name: 'ContentEditor',
  components: { ContentCard, Attributer, EditorContent, EditorFloatingMenu, EditorMenuBar },
  props: ['type', 'isNewContent', 'oslug'],

  data() {
    return {
      content: null,
      editor: null,
      tags: null,
      slugWasEdited: false,
      isUploadingCover: false,
      isEditorEmpty: true,
    }
  },

  created() {
    window.addEventListener('drop', this.windowDropDisabler)
    window.addEventListener('dragover', this.windowDropDisabler)
  },

  watch: {
    $route: 'reinit',
  },

  mounted() {
    this.reinit()
  },

  methods: {
    getJSON() {
      console.log(this.editor.getJSON())
    },

    reinit() {
      if(!this.isNewContent) {
        this.$root.makeAPIRequest('content', 'get', {
          type: this.type,
          slug: this.oslug,
        })
          .then(response => {
            if(response.data.error) {
              this.$router.replace({ name: 'not-found' })
            }

            if(!this.$root.auth) {
              this.$router.replace({ name: 'forbidden' })
            }

            if(!response.data.result.isEditable) {
              this.$router.replace({ name: 'forbidden' })
            }

            if(!response.data.result.isModern) {
              this.$router.replace({ name: 'forbidden' })
            }

            const json = JSON.parse(response.data.result.body)
            const content = {
              isPubliclyEditable: response.data.result.isPubliclyEditable,
              title: response.data.result.title,
              slug: this.oslug,
              tags: response.data.result.tags,
              body: json,
              isModern: true,
              cover: response.data.result.cover,
              color: response.data.result.color,
              description: response.data.result.description,
            }

            this.tags = content.tags.join(', ')
            this.content = content
            this.initEditor()
          })
          .catch(() => {
            this.$router.replace({ name: 'not-found' })
          })
        return
      }

      const content = {
        title: '',
        slug: '',
        cover: null,
        color: {
          red: 0.99,
          green: 0.99,
          blue: 0.99,
        },
        description: '',
      }
      if(this.type !== 'question') {
        content.tags = []
        content.body = '<p></p>'
        content.isModern = true
        this.tags = content.tags.join(', ')
      }
      if(this.type === 'article') {
        content.isPubliclyEditable = true
      }
      if(this.type === 'event') {
        content.format = ''
        content.organizer = ''
        content.phone = ''
        content.website = ''
        content.address = ''
        content.date = ''
        content.time = ''
        content.price = ''
      }

      this.content = content
      if(this.type !== 'question') {
        this.initEditor()
      }
    },

    initEditor() {
      this.editor = new Editor({
        content: this.content.body,
        extensions: [
          new Heading(),
          new Bold(),
          new Italic(),
          new Underline(),
          new Image(),
          new Video(),
          new Link(),
          new ListItem(),
          new BulletList(),
          new OrderedList(),
          new Placeholder(),
          new Blockquote(),
          new History(),
          new CodeBlock(),
          new TrailingParagraph(),
        ],
        onUpdate: this.textUpdated
      })
      this.isEditorEmpty = false
    },

    windowDropDisabler(event) {
      event.preventDefault()
    },

    showLogInOverlay(event) {
      event.preventDefault()
      this.$root.overlay = 'log-in'
    },

    textUpdated(editorProps) {
      this.isEditorEmpty = editorProps.state.doc.textContent.length === 0
      const content = editorProps.state.doc.content.content
      for(const node of content) {
        if(node.type.name !== 'paragraph') {
          continue
        }

        const text = node.textContent
        if(text.length < 1) {
          continue
        }

        this.content.description = text
        return
      }

      this.content.description = ''
    },

    publish() {
      const content = {
        title: this.content.title.trim(),
        slug: this.slug,
        cover: this.content.cover,
        type: this.type,
      }
      if(this.type !== 'question') {
        content.tags = this.arrayTags
        content.body = JSON.stringify(this.editor.getJSON())
        content.description = this.content.description
      }
      if(this.type === 'article') {
        content.isPubliclyEditable = this.content.isPubliclyEditable
      }
      if(this.type === 'event') {
        content.format = this.content.format
        content.organizer = this.content.organizer
        content.phone = this.content.phone
        content.website = this.content.website
        content.address = this.content.address
        content.date = this.content.date
        content.time = this.content.time
        content.price = this.content.price
      }

      if(!this.$root.auth) {
        alert('Вы должны быть авторизованы, чтобы опубликовывать материалы.')
        return
      }

      if(content.title.length < 20 || content.title.length > 150) {
        alert('Длина заголовка должна быть от 20 до 150 символов.')
        return
      }

      if(this.type !== 'question' && (content.description.length < 100 || content.description.length > 250)) {
        alert('Первый абзац текста должен быть от 100 до 250 символов длиной.\nОн будет отображаться на карточке материала, а также является кратким описанием материала для поисковиков.')
        return
      }

      const slugRegex = /^[a-z0-9]+(-[a-z0-9]+)*$/
      if(!slugRegex.test(content.slug) || content.slug.length > 100) {
        alert('Ссылка должна удовлетворять следующим правилам:\n- В ссылке могут присутствовать только маленькие латинские буквы, цифры, а также символ минуса\n- Ссылка не может начинаться с минуса или заканчиваться минусом\n- В ссылке не могут присутствовать два минуса подряд\n- Длина ссылка должна быть не более 100 символов')
        return
      }

      if(this.type !== 'question' && content.tags.length < 1) {
        alert('Требуется указать хотя бы один тег.')
        return
      }

      if(!content.cover) {
        alert('Для публикации материала требуется загрузить обложку.')
        return
      }

      if(this.type !== 'question' && this.isEditorEmpty) {
        alert('Вы не разметили основной текст материала.')
        return
      }

      if(this.type === 'event') {
        if(this.content.format.length < 1) {
          alert('Требуется указать формат мероприятия.')
          return
        }

        if(this.content.organizer.length < 1) {
          alert('Требуется указать организатора мероприятия.')
          return
        }

        if(this.content.address.length < 1) {
          alert('Требуется указать адрес проведения мероприятия.')
          return
        }

        if(this.content.date.length < 1) {
          alert('Требуется указать дату проведения мероприятия.')
          return
        }

        if(this.content.time.length < 1) {
          alert('Требуется указать время проведения мероприятия.')
          return
        }
      }

      this.$root.makeAPIRequest('content', 'publish', content)
        .then(response => {
          if(response.data.error) {
            console.error(`Ошибка публикации материала: ${response.data.message}`)
            alert(response.data.message)
            return
          }

          const contentReader = `${response.data.result.type}-content-reader`
          const slug = response.data.result.slug
          this.$router.replace({
            name: contentReader,
            params: { slug },
          })
        })
    },

    selectCoverFile() {
      if(this.isUploadingCover) {
        return
      }

      if(!this.$root.auth) {
        alert('Вы должны войти, чтобы загружать обложки для материалов.')
        return
      }

      this.$refs.fileInput.click()
    },

    uploadCover(event) {
      const files = event.target.files
      if(files.length !== 1) {
        return
      }

      const file = files[0]
      if(!(file instanceof File)) {
        alert('Неизвестная ошибка. Попробуйте снова.')
        return
      }

      const formData = new FormData()
      formData.append('file', file, 'imagefile')
      const headers = {
        'Content-Type': 'multipart/form-data',
        'X-HN-Should-Return-Color': 'YES',
        'X-HN-Token': this.$root.auth.token,
      }

      this.isUploadingCover = true

      axios.post(
        `${this.$root.globalParams.developerBaseURI}/upload-content-image`,
        formData,
        { headers },
      )
        .then(response => {
          this.isUploadingCover = false

          if(response.data.error) {
            console.error(`Ошибка загрузки обложки: ${response.data.message}`)
            alert(`Ошибка загрузки: ${response.data.message}.`)
            return
          }

          this.content.cover = response.data.result.uuid
          this.content.color = response.data.result.color
        })
        .catch(error => {
          this.isUploadingCover = false
          console.error(`Ошибка загрузки обложки: ${error.response}`)
          alert('Ошибка загрузки. Проверьте соединение с интернет.')
        })
    },

    debugPasted(event) {
      const clipboardData = event.clipboardData
      const types = clipboardData.types
      if(!types.includes('text/html')) {
        console.log('No text/html')
        console.log(types)
        return
      }
      const html = clipboardData.getData('text/html')
      console.log(html)
    },

    addImageClicked(event) {
      event.preventDefault()
      event.stopPropagation()
      alert('Чтобы добавить изображение, перетащите файл в редактор.')
    },
  },

  computed: {
    title: {
      get() {
        return this.content.title
      },
      set(newTitle) {
        if(!this.content) {
          return
        }

        this.content.title = newTitle
        if(this.slugWasEdited || !this.isNewContent) {
          return
        }
        this.content.slug = slugify(newTitle, {
          lower: true,
          remove: /[^A-Za-z0-9\s-]/g,
        })
      }
    },

    slug: {
      get() {
        return this.content.slug
      },
      set(newSlug) {
        if(!this.content) {
          return
        }

        this.slugWasEdited = true
        this.content.slug = newSlug || ''
      },
    },

    arrayTags() {
      const tags = this.tags.split(/[,\s\n]/)
      const trimmedTags = tags.map(tag => tag.trim())
      const filteredTags = trimmedTags.filter(tag => tag.length > 0)

      return filteredTags
    },

    cardImage() {
      if(this.content.cover === null) {
        return `/images/cards-generics/${this.type}.svg`
      }

      if(this.content.cover.startsWith('/public/images/')) {
        return `${this.$root.globalParams.developerBaseURI}${this.content.cover}`
      }

      const relativePath = '/user-content/content/'
      const extension = 'jpg'
      return `${this.$root.globalParams.developerBaseURI}${relativePath}${this.content.cover}/orig.${extension}`
    },
  },

  beforeDestroy () {
    this.editor.destroy()
  },

  destroyed() {
    window.removeEventListener('drop', this.windowDropDisabler)
    window.removeEventListener('dragover', this.windowDropDisabler)
  },
}
</script>

<style lang="less" scoped>
  @import (reference) '../shared.less';
  @import '../content-views.less';
  @import '../card-shared.less';

  @transition-time: 0.2s;

  .main-wrap {
    @media @tablet-m {
      flex-direction: column;
    }
  }

  section {
    width: 0;

    @media @tablet-m {
      width: 100%;
    }
  }

  .promo-text p {
    line-height: 1.4;
  }

  .fields {
    display: flex;
    flex-direction: column;

    .segments {
      .segments();
      width: unset;
      align-self: flex-start;
    }

    .input-field {
      display: flex;
      flex-direction: column;

      label {
        margin: 0;
        padding: 0.5em 0.85em;
        font-weight: 600;

        &.required::after {
          content: ' *';
          color: @color-error;
        }
      }

      textarea {
        .input();
        background-color: @color-page-background;
        resize: none;
      }
    }
  }

  .auth-info {
    padding: 1em 0;
    background-color: @color-block-background;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  article {
    position: relative;
    margin-top: @s-margin;
  }

  .editor-menu-bar {
    position: sticky;
    top: 0;
    background-color: @color-page-background;
    padding: @icon-margin 0;
    z-index: @z-index-editor-menu-bar;
  }

  .editor-content {
    z-index: @z-index-editor-content;
  }

  .editor-floating-menu {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: opacity @transition-time, visibility @transition-time;
    top: var(--menu-top);
    margin-top: -2.5px;
    left: 5px;
    z-index: @z-index-editor-floating-menu;

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }

  .editor-menu-bar button,
  .editor-floating-menu button {
    appearance: none;
    border: unset;
    width: 40px;
    height: 21px;
    background-color: @color-block-background;
    margin: 0 2px 0 0;
    padding: unset;
    cursor: pointer;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    &:last-child {
      margin-right: unset;
    }

    &:hover,
    &.active {
      background-color: @color-accent;

      img {
        filter: invert(100%);
      }
    }

    img {
      height: 10px;
      filter: invert(0%);
    }
  }

  .sticky-wrapper {
    position: sticky;
    top: @s-margin;
    display: flex;
    flex-direction: column;

    @media @tablet-m {
      flex-direction: column-reverse;
    }
  }

  .button {
    .big-button();
    background-color: @color-accent;
    margin-bottom: @s-margin;

    @media @tablet-m {
      margin-bottom: unset;
      margin-top: @s-margin;
    }

    &.publish.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .file-upload-input {
    display: none;
  }

  .content-card-wrapper {
    height: @card-high-height;
    cursor: pointer;

    &.small {
      height: @card-small-height;
    }

    @media @tablet-m {
      height: @card-small-height;
    }
  }

  .card {
    height: 100%;
    pointer-events: none;
  }
</style>

<style lang="less">
  @import '../content-body.less';

  p.is-editor-empty::before {
    content: attr(data-placeholder-text);
    opacity: 0.5;
    float: left;
    pointer-events: none;
    height: 0;
  }
</style>
